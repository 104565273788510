.quickpay-container {
    padding-bottom: 10rem;
    padding-top: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: calc(100vh - 600px);
    background-color: white;
}

@media (min-width: 640px) {
    .quickpay-container {
        padding-bottom: 16rem;
    }
}

@media (min-width: 768px) {
    .quickpay-container {
        min-height: calc(100vh - 600px);
    }
}

@media (min-width: 1024px) {
    .quickpay-container {
        min-height: calc(100vh - 400px);
    }
}

.quickpay-flex-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media (min-width: 1024px) {
    .quickpay-flex-container {
        flex-direction: row;
    }
}

.quickpay-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.quickpay-left-inner {
    width: 100%;
}

.quickpay-card {
    max-width: 32rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.account-info {
    font-weight: 500;
    font-size: 1.25rem;
    background-color: white;
    border: 1px solid #295B7E;
    border-radius: 0.375rem;
    width: fit-content;
    padding: 0.5rem 0.75rem;
}

.account-number {
    text-decoration: underline;
}

.credit-card-container {
    border-radius: 0.375rem;
    border: 1px solid #295B7E;
    max-width: 75%;
    padding: 1rem 1rem 1.5rem;
    margin-top: -2rem;
}

.user-name {
    font-weight: 500;
}

.quickpay-right {
    flex: 1;
    display: none;
}

@media (min-width: 1024px) {
    .quickpay-right {
        display: block;
    }
}

.image-container {
    width: 66.666667%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1280px) {
    .image-container {
        width: 83.333333%;
    }
}

.billpay-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
