.form-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr; /* Default for mobile */
}

.payment-info-container {
    display: flex;
    flex-direction: column; /* Default for mobile */
    gap: 1rem; /* Equivalent to sm:gap-4 (4 x 0.25rem = 1rem) */
}

@media (min-width: 768px) {
    .form-container {
        grid-template-columns: 1fr 1fr; /* Two-column layout */
    }

    .payment-info-container {
        flex-direction: row; /* Change to row for larger screens */
    }
}
