.code-editor {
  display: flex;
  height: 200px;
  overflow-y: auto;
  background-color: #f5f5f5;
  border-right: 1px solid #ccc;
  padding: 5px 0;
}

.line-numbers {
    width: 40px;
    background-color: #f5f5f5;
    border-right: 1px solid #ccc;
    padding: 8px 0;
    text-align: right;
    white-space: pre-wrap;
    max-height: 100%;
}

.line-numbers::-webkit-scrollbar,
.code-input::-webkit-scrollbar {
  display: none;
}

.line-number {
  padding: 0 5px;
  font-size: 14px;
  line-height: 1.5;
}

.code-input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 1.5;
  resize: none;
  height: 900px;
  /* overflow-y: auto; */
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
