/* General Styles */
.pricing-container {
    width: 100%;
}

.hero-section {
    width: 100%;
    text-align: center;
}

.hero-heading {
    font-size: 2rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 2rem;
}

.plans-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.plan-column {
    width: 100%;
    max-width: 50%;
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
}

.plan-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.plan-description {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #295B7E;
}

.plan-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #295B7E; /* Custom Primary Color */
    margin-bottom: 1rem;
}

.start-membership-btn {
    padding: 0.75rem 2rem;
    background-color: #295B7E;
    color: #fff;
    border-radius: 0.375rem;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    transition: background-color 0.3s;
    cursor: pointer;
}

.start-membership-btn:hover {
    background-color: #53758d;
}

/* Loader */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    animation: spin 1s infinite;
    height: 2rem;
    width: 2rem;
    border: 4px solid #295B7E;
    border-radius: 50%;
    margin-right: 0.75rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Styles */

/* Tablet Styles (max-width: 1024px) */
@media (max-width: 1024px) {
    .plans-section {
        flex-direction: column;
    }

    .plan-column {
        max-width: 100%;
        padding: 1rem 0;
    }

    .plan-heading {
        font-size: 1.75rem;
    }

    .plan-description {
        font-size: 1rem;
    }

    .plan-price {
        font-size: 1.125rem;
    }

    .start-membership-btn {
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
    }
}

/* Mobile Styles (max-width: 600px) */
@media (max-width: 600px) {
    .pricing-container {
        padding: 1rem;
    }

    .hero-heading {
        font-size: 1.5rem;
    }

    .plans-section {
        flex-direction: column;
        padding: 0;
    }

    .plan-column {
        width: 100%;
        padding: 1rem 0;
    }

    .plan-heading {
        font-size: 1.5rem;
    }

    .plan-description {
        font-size: 0.875rem;
    }

    .plan-price {
        font-size: 1rem;
    }

    .start-membership-btn {
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
    }

    .loader {
        height: 1.5rem;
        width: 1.5rem;
    }
}

/* Section for Get Started Free Trial */
.get-started-section {
    background-color: #f8f8f8;
    padding: 3rem 1rem;
}

.get-started-text {
    text-align: center;
    margin-bottom: 2rem;
}

.get-started-heading {
    font-size: 2rem;
    font-weight: 600;
}

.get-started-description {
    font-size: 1rem;
    text-align: justify;
    margin-bottom: 2rem;
}

/* Grid for Free Trial Features */
.features-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.feature-item {
    padding: 1rem;
}

.feature-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
}

.feature-description {
    font-size: 0.875rem;
    text-align: justify;
}

@media (max-width: 768px) {
    .features-grid {
        grid-template-columns: 1fr;
    }

    .feature-heading {
        font-size: 1.25rem;
    }

    .feature-description {
        font-size: 0.875rem;
    }
}
