:root {
  --secondary: #295b7e;
}

* {
  font-family: "Inter", sans-serif !important;
}

body,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  overflow-y: auto;
}

body::-webkit-scrollbar,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}

body::-webkit-scrollbar-track,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-track {
  width: 0.75rem;
  height: 0.75rem;
}

body::-webkit-scrollbar-thumb,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 25px;
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 100px;
  color: #000;
  margin: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.loading span {
  position: absolute;
  height: 10px;
  width: 84px;
  top: 50px;
  overflow: hidden;
}
.loading span > i {
  position: absolute;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  -webkit-animation: wait 4s infinite;
  -moz-animation: wait 4s infinite;
  -o-animation: wait 4s infinite;
  animation: wait 4s infinite;
}
.loading span > i:nth-of-type(1) {
  left: -28px;
  background: #92a2ab;
}
.loading span > i:nth-of-type(2) {
  left: -21px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: var(--secondary);
}

@-webkit-keyframes wait {
  0% {
    left: -7px;
  }
  30% {
    left: 52px;
  }
  60% {
    left: 22px;
  }
  100% {
    left: 100px;
  }
}
@-moz-keyframes wait {
  0% {
    left: -7px;
  }
  30% {
    left: 52px;
  }
  60% {
    left: 22px;
  }
  100% {
    left: 100px;
  }
}
@-o-keyframes wait {
  0% {
    left: -7px;
  }
  30% {
    left: 52px;
  }
  60% {
    left: 22px;
  }
  100% {
    left: 100px;
  }
}
@keyframes wait {
  0% {
    left: -7px;
  }
  30% {
    left: 52px;
  }
  60% {
    left: 22px;
  }
  100% {
    left: 100px;
  }
}

/* Collapse Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: #f2f7fa;
  z-index: 100;
  margin-top: 16px;
}
.sidebar.close {
  width: 50px;
}

.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover:not(.not-list-icon) {
  background: #eeeeee;
}

.not-list-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar .nav-links li i {
  height: 50px;
  min-width: 50px;
  text-align: center;
  line-height: 50px;
  color: #58707b;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar.close .nav-links i.arrow {
  display: none;
}

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 14px;
  color: #58707b;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #eeeeee;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu a {
  color: #58707b;
  font-size: 12px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 14px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

/* Override gray background color for timeline elements */
.react-calendar-timeline .rct-header-root {
  background-color: transparent !important;
}

.react-calendar-timeline .rct-events {
  background-color: transparent !important;
}

.react-calendar-timeline .rct-sidebar {
  background-color: transparent !important;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
}

.dropdown:hover {
  background-color: #607d8b;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content p {
  float: none;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  margin-block: 0;
  cursor: pointer;
}

rect.highcharts-background {
  fill: #eceff1;
}

rect.highcharts-button-box {
  fill: #eceff1;
}

@media print {
  rect.highcharts-background {
    fill: white;
    display: flex;
  }

  g.highcharts-exporting-group {
    display: none;
  }
}

.rdw-list-dropdown {
  z-index: 0;
}

.rdw-text-align-dropdown {
  z-index: 0;
}
