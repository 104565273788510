table {
    font-size: 12px;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child {
    background-color: #dddddd;
  }
  
  .wrapper-class {
    /* padding: 1rem; */
    border: 1px solid #ccc;
    padding: "0px";
    margin: "0px";
  }
  .editor-class {
    height: "150px";
    background-color: white;
    padding: 1rem;
    border: 1px solid #ccc;
    padding: "5px";
    margin: "0px";
  }
  .toolbar-class {
    border: 1px solid #ccc;
    padding: "0px";
    margin: "0px";
  }