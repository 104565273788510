.container .bar {
    transition-duration: 0.3s;
    transition-property: filter, opacity;
    filter: saturate(100%);
    opacity: 1;
    cursor: pointer;
  }
  
  .container.hasHighlight .bar {
    filter: saturate(50%);
    opacity: 0.2;
  }
  
  .container.hasHighlight .bar:hover {
    filter: saturate(100%);
    opacity: 1;
  }
  
  .barHover {
    filter: saturate(100%) !important;
    opacity: 1 !important;
  }
  
  .barchart .legendItem {
    transition-duration: 0.3s;
    transition-property: filter;
  }
  
  .barchart .legendItem:hover {
    filter: saturate(300%) contrast(200%);
  }
  
  .dropdownItem:hover {
    background-color: #ECEFF1;
  }
  
  .tooltip {
    pointer-events: none; /* Prevent mouse events */
  }
  
  .tooltipContent {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
  }
  
  .barchart {
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .barchart::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.75rem;
  }
  
  .barchart::-webkit-scrollbar-track {
    width: 0.75rem;
    height: 0.75rem;
  }
  
  .barchart::-webkit-scrollbar-thumb {
    background: var(--secondary);
  }

  .axis {
    font: 10px sans-serif;
  }
  
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }
  
  .x.axis path {
    display: none;
  }