.calendar,
.calendar > * {
  box-sizing: border-box !important;
}

.calendar table {
  font-size: 12px !important;
  border-collapse: collapse !important;
  border-spacing: 0px !important;

  table-layout: fixed !important;
  width: 100% !important;

  border: 1px solid #d3d3d3 !important;
}

.calendar thead,
.calendar tbody,
.calendar tr {
  border-collapse: collapse !important;
  border-spacing: 0px;
}

.calendar td,
.calendar th {
  border-collapse: collapse !important;
  border-spacing: 0px !important;
  padding: 0px !important;
  vertical-align: top !important;
}

.calendar .calendar_event_title:hover {
  text-decoration: underline;
}

.calendar-scrollbar {

}

.calendar-scrollbar::-webkit-scrollbar {
 
}

.calendar-scrollbar::-webkit-scrollbar-track {  
 
}

.calendar-scrollbar::-webkit-scrollbar-thumb {
  
}

.calendar_skeleton {
  animation: calendar-skeleton-loading 1s linear infinite alternate;
}

@keyframes calendar-skeleton-loading {
  0% {
    background-color: hsl(200, 16%, 70%);
    border: hsl(200, 16%, 70%);
    color: transparent;
  }
  100% {
    background-color: hsl(200, 16%, 85%);
    border: hsl(200, 16%, 85%);
    color: transparent;
  }
}

.calendar-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--secondary);
  animation: l10 1.5s infinite linear;
}
@keyframes l10 {
  0% {
    box-shadow: 0 -30px #eceff1, calc(30px * 0.707) calc(-30px * 0.707) #92a2ab,
      30px 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1,
      0 0 #92a2ab;
  }
  12.5% {
    box-shadow: 0 0 #eceff1, calc(30px * 0.707) calc(-30px * 0.707) #92a2ab,
      30px 0 #eceff1, calc(30px * 0.707) calc(30px * 0.707) #92a2ab, 0 0 #eceff1,
      0 0 #92a2ab, 0 0 #eceff1, 0 0 #92a2ab;
  }
  25% {
    box-shadow: 0 0 #eceff1, 0 0 #92a2ab, 30px 0 #eceff1,
      calc(30px * 0.707) calc(30px * 0.707) #92a2ab, 0 30px #eceff1, 0 0 #92a2ab,
      0 0 #eceff1, 0 0 #92a2ab;
  }
  37.5% {
    box-shadow: 0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1,
      calc(30px * 0.707) calc(30px * 0.707) #92a2ab, 0 30px #eceff1,
      calc(-30px * 0.707) calc(30px * 0.707) #92a2ab, 0 0 #eceff1, 0 0 #92a2ab;
  }
  50% {
    box-shadow: 0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1, 0 0 #92a2ab,
      0 30px #eceff1, calc(-30px * 0.707) calc(30px * 0.707) #92a2ab,
      -30px 0 #eceff1, 0 0 #92a2ab;
  }
  62.5% {
    box-shadow: 0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1,
      calc(-30px * 0.707) calc(30px * 0.707) #92a2ab, -30px 0 #eceff1,
      calc(-30px * 0.707) calc(-30px * 0.707) #92a2ab;
  }
  75% {
    box-shadow: 0 -30px #eceff1, 0 0 #92a2ab, 0 0 #eceff1, 0 0 #92a2ab,
      0 0 #eceff1, 0 0 #92a2ab, -30px 0 #eceff1,
      calc(-30px * 0.707) calc(-30px * 0.707) #92a2ab;
  }
  87.5% {
    box-shadow: 0 -30px #eceff1, calc(30px * 0.707) calc(-30px * 0.707) #92a2ab,
      0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1,
      calc(-30px * 0.707) calc(-30px * 0.707) #92a2ab;
  }
  100% {
    box-shadow: 0 -30px #eceff1, calc(30px * 0.707) calc(-30px * 0.707) #92a2ab,
      30px 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1, 0 0 #92a2ab, 0 0 #eceff1,
      0 0 #92a2ab;
  }
}
