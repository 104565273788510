/* Default Styles (Desktop) */
.feature-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem 1rem;
}

.text-container {
    text-align: center;
    color: white;
    padding: 2.5rem 1rem;
}

.headline {
    font-size: 2rem;
    font-weight: 600;
    color: black;
}

.feature-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
        margin-bottom: 2rem;
    padding: 0 1rem;
}

.feature-text {
    width: 100%;
    max-width: 50%;
    padding: 4rem;
}

.feature-title {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
}

.feature-description {
    font-size: 1rem;
    text-align: justify;
}

.feature-image {
    width: 100%;
    max-width: 50%;
}

.feature-image img {
    width: 100%;
    height: 20rem;
}

/* Responsive Styles for Tablet (max-width: 1024px) */
@media (max-width: 1024px) {
    .feature-row {
        flex-direction: column;
        align-items: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    .feature-text {
        max-width: 100%;
        text-align: center;
        padding: 1rem;
    }

    .feature-title {
        font-size: 1.75rem;
    }

    .feature-description {
        font-size: 0.95rem;
    }

    .feature-image {
        max-width: 100%;
        padding-top: 1rem;
    }

    .feature-image img {
        height: 18rem;
    }
}

/* Responsive Styles for Mobile (max-width: 600px) */
@media (max-width: 600px) {
    .feature-section {
        padding: 1.5rem 1rem;
    }

    .headline {
        font-size: 1.5rem;
    }

    .feature-row {
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        padding: 0 1rem;
    }

    .feature-text {
        max-width: 100%;
        text-align: center;
        padding: 1rem;
    }

    .feature-title {
        font-size: 1.5rem;
    }

    .feature-description {
        font-size: 0.875rem;
        text-align: center;
    }

    .feature-image img {
        height: 16rem;
    }
}


.features-section-box {
    width: 100%;
    padding: 3rem 1rem;
    background-color: #295B7E;
    color: #f3f3f3;
}

.features-container-box {
    text-align: center;
}

.features-heading-box {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.features-list-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.feature-item-box {
    padding: 1rem;
    width: 100%;
    max-width: calc(33.333% - 1rem);
    box-sizing: border-box;
}

.feature-content-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 1.5rem;
}

.feature-number-box {
    font-size: 4rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1rem;
}

.feature-name-box {
    font-size: 1.25rem;
    font-weight: bold;
    color: white;
    margin-bottom: 0.5rem;
}

.feature-description-box {
    font-size: 1rem;
    color: white;
    margin: 0.5rem 0;
}

@media (max-width: 1024px) {
    .features-list-box {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .feature-item-box {
        max-width: calc(50% - 1rem);
    }

    .feature-number-box {
        font-size: 3rem;
    }

    .feature-name-box {
        font-size: 1.125rem;
    }

    .feature-description-box {
        font-size: 0.95rem;
    }
}

@media (max-width: 600px) {
    .features-section-box {
        padding: 2rem 1rem;
    }

    .features-heading-box {
        font-size: 1.5rem;
    }

    .features-list-box {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .feature-item-box {
        max-width: 100%;
        margin-bottom: 2px;
    }

    .feature-number-box {
        font-size: 2.5rem;
    }

    .feature-name-box {
        font-size: 1rem;
    }

    .feature-description-box {
        font-size: 0.875rem;
    }
}

