.nested-dropdowns {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 100;
  background-color: white;
}

.nested-dropdowns li {
  cursor: pointer;
  padding: 0.25rem;
  position: relative;
  background-color: white;
  width: 9em;
  padding: 5px 0px 5px 15px;
}

.nested-dropdowns ul {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: none;
  right: 80;
  position: absolute;
  top: 100%;
  list-style-type: none;
  margin: 0;
  margin-top: 5px;
  padding: 0;
}

.nested-dropdowns ul ul {
  right: 100%;
  position: absolute;
  top: 0;
}

.nested-dropdowns__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nested-dropdowns li:hover {
  background-color: #f2f7fa;
}

.nested-dropdowns li:hover > ul,
.nested-dropdowns li:active > ul {
  display: block;
}
