
.get-started-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2.5rem;
    padding-right: 1.5rem;
}

.content-container {
    display: flex;
    flex-direction: row;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.left-column {
    width: 100%;
    max-width: 75%;
    padding: 0.25rem;
}

.headline {
    color: #295B7E;
    font-size: 1.875rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
    text-align: left;
}

.description {
    font-size: 1.125rem;
    font-weight: 400;
    text-align: left;
    padding-bottom: 0.5rem;
    color: #000;
}

.right-column {
    width: 100%;
    max-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}

.cta-button {
    background-color: #295B7E;
    color: #FFFFFF;
    font-weight: 600;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 2rem;
    border-radius: 0.375rem;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
}

@media (max-width: 1024px) {
    .get-started-section {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .content-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .left-column {
        max-width: 100%;
        text-align: center;
    }

    .right-column {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
    }

    .headline {
        font-size: 1.5rem;
    }

    .description {
        font-size: 1rem;
    }
}

@media (max-width: 600px) {
    .get-started-section {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .content-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .left-column {
        max-width: 100%;
        text-align: center;
    }

    .right-column {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
    }

    .headline {
        font-size: 1.875rem;
        font-weight: 400;
        text-align: center;
    }

    .description {
        font-size: 0.875rem;
        text-align: center;
    }

    .cta-button {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-size: 0.875rem;
        font-weight: 100%;
        width: 400px;
        margin-top: 1rem;
        text-align: center;
        display: inline-block;
    }
}
