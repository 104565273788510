
.footer-container {
    background-color: #295B7E;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: sans-serif;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    justify-items: center;
}

@media (max-width: 640px) {
    .footer-container {
        padding-left: 4rem;
        padding-right: 2rem;
    }

    .footer-content {
        justify-items: start;
        text-align: left;
    }

    .footer-section {
        align-items: flex-start;
        gap: 2px;
    }

    .footer-logo {
        justify-self: center;
        text-align: center;
    }

    .footer-item {
        margin-bottom: 1px;
    }

    .footer-title {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}


@media (min-width: 640px) {
    .footer-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .footer-content {
        grid-template-columns: repeat(4, 1fr);
    }
}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo-img {
    width: 7rem;
    height: 7rem;
}

.footer-section {
    display: flex;
    flex-direction: column;
    gap: 0.75rem
}

.footer-title {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: bold;
}

.footer-list {
    list-style-type: none;
    padding: 0;
}

.footer-item {
    color: #ffffff;
    margin-bottom: 0.5rem;
}

.footer-link {
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-link:hover {
    color: #9a9a9a;
}

.footer-divider {
    margin: 2rem 0;
    border: 1px solid #4b4b4b;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.footer-copy {
    color: #ffffff;
    font-size: 0.875rem;
}

.footer-company-link {
    color: #ffffff;
    text-decoration: none;
    margin: 0 0.25rem;
}

.footer-social-links {
    display: flex;
    gap: 1rem;
}

.footer-social-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-social-icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: #ffffff;
    transition: fill 0.3s ease;
}

.footer-social-link:hover .footer-social-icon {
    fill: #ffffff;
}