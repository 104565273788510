.top-bar-icon-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
  }
  
  .top-bar-icon-logo {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  .selected-logo {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  