
@media (min-width: 768px) {
    .mobile-only {
      display: none !important;
    }
  }
  
  @media (max-width: 768px) {
    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: flex !important;
    }
}
