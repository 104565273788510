
@media (min-width: 768px) {
    .xl-flex-row {
        flex-direction: column;
    }
}

@media (min-width: 768px) {
    .xl-width-40 {
        width: 100%; /* Apply 40% width on large screens */
    }
}

  