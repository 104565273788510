.container .slice {
  transition-duration: 0.3s;
  transition-property: filter, opacity;
  filter: saturate(100%);
  opacity: 1;
  cursor: pointer;
}

.container.hasHighlight .slice {
  filter: saturate(50%);
  opacity: 0.2;
}

.container.hasHighlight .slice:hover {
  filter: saturate(100%);
  opacity: 1;
}

.sliceHover {
  filter: saturate(100%) !important;
  opacity: 1 !important;
}

.piechart .legendItem {
  transition-duration: 0.3s;
  transition-property: filter;
}

.piechart .legendItem:hover {
  filter: saturate(300%) contrast(200%);
}

.dropdownItem:hover {
  background-color: #ECEFF1;
}

.tooltip {
  pointer-events: none; /* Prevent mouse events */
}

.tooltipContent {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
}

.piechart {
  overflow-x: auto;
  overflow-y: hidden;
}

.piechart::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}

.piechart::-webkit-scrollbar-track {
  width: 0.75rem;
  height: 0.75rem;
}

.piechart::-webkit-scrollbar-thumb {
  background: var(--secondary);
}
